<script setup lang="ts">
import { Tab_Filter_Type } from '~/enums/tab_filter-type'
import { FreeVipApi } from '~/net/apis/free_vip_api'

const videoTypeList = [
  {
    tabId: Tab_Filter_Type.newest,
    title: '最新作品'
  },
  {
    tabId: Tab_Filter_Type.watch,
    title: '最多观看'
  },
  {
    tabId: Tab_Filter_Type.collect,
    title: '最多收藏'
  }
]
const onClickLeft = () => history.back()
const active = ref(Tab_Filter_Type.newest)

// const blocks = ref<Array<any>>([])
// FreeVipApi.query({
//   pageNum: 1,
//   pageSize: 10,
//   orderKey: active.value,
//   viewType: 0
// }).then(({ data }) => {
//   blocks.value.concat(data.record)
// })
</script>

<template>
  <div>
    <van-nav-bar title="限免区" left-arrow @click-left="onClickLeft" />
    <van-tabs v-model:active="active">
      <van-tab v-for="item in videoTypeList" :key="item.tabId" :name="item.tabId" :title="item.title">
        <PullRefreshList
          wrap-class="w-full grid grid-cols-3 gap2 px-15px"
          :request-api="FreeVipApi.query"
          :offset-list="20"
          :request-params="{
            orderKey: active,
            viewType: 0
          }"
        >
          <template #="{ data }">
            <VertucakCard :item="(data as BannerFilmModel).javVideoBaseDTO" />
          </template>
        </PullRefreshList>
      </van-tab>
    </van-tabs>
  </div>
</template>
